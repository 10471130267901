import React from 'react'
import { H1, H2, H3, H5, Paragraph1 } from '@styles/Global.styles'
import { Grid, Icon } from 'semantic-ui-react'
import { CheckoutSelectionCard } from '@components/CheckoutSelectionCard'
import { PlateOwnerSelectionSubHeading } from './PlateOwnerSelection.styles'
import { CheckoutSelectionCardModel } from '@models/Checkout/CheckoutSelectionCard'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { Checkout } from '@models/Checkout/Checkout'
import { CheckoutSelectionCardContainer, CheckoutSelectionCardHeading, CheckoutSelectionCardIndicator } from '@components/CheckoutSelectionCard/CheckoutSelectionCard.styles'
import { GiftingOptionsSelectionCardButton } from '@components/Mobile/GiftingSelection/GiftingCheckoutSelectionCard.styles'
import EcardContainer from '@components/GiftingSelection/EcardContainer'
import { CheckoutGiftingCardModel } from '@components/Mobile/GiftingSelection/mapper'
import { getCurrentCheckout } from '@redux/localStorage/checkout'
import { EcardImg } from '@components/Mobile/GiftingSelection/EcardContainer.style'
import { navigate } from 'gatsby'
import CheckoutSelectionCardWithRadios from '@components/CheckoutSelectionCard/CheckoutSelectionCardWithRadios'
import { TransactionTypesSetting } from '@models/ProductModels'

const ecardImg = require('@assets/images/ecard.png')

export type PlateOwnerSelectionProps = {
    heading: string
    subHeading: string
    body: string
    applicantCard: CheckoutSelectionCardModel
    giftingCard: CheckoutSelectionCardModel
    forSomeoneElseCard: CheckoutSelectionCardModel
    eCard: CheckoutSelectionCardModel
    updateOwnerIsApplicant: (ownerIsApplicant:boolean) => void
    updateManufactureNow: (manufactureNow:boolean)=>void
    updateIsGifting: (isGifting: boolean) => void
    updateGiftingType: (giftingType: number) => void
    updatePersonalDetails: (personalDetails: Checkout) => void
    checkout: Checkout
    giftingOptionElements: any
    apiUrl: string
    eCardTemplate: CheckoutGiftingCardModel
    transactionTypeId: number
}

interface States {
    giftingType : number
    isSelected : boolean,
    giftingSelected: boolean
}

class PlateOwnerSelection extends React.Component<PlateOwnerSelectionProps, States>{

    state = {
        giftingType : getCurrentCheckout().giftingType,
        isSelected : false,
        giftingSelected : this.props.checkout.isGifting
    }
    selectApplicantOption = () => {
        this.props.updateOwnerIsApplicant(true);
        this.props.updateIsGifting(false);
        if(!this.props.checkout.isLayby) this.props.updateManufactureNow(true);
        this.setState({giftingType: 0})
        this.props.updateGiftingType(0)
    }

    selectGiftingOption = () => {
        setTimeout(() => {
            this.props.updateOwnerIsApplicant(false);  
            if (this.props.transactionTypeId != TransactionTypesSetting.New.id) {            
                this.setState({giftingSelected: true})
            } 
            this.props.updateIsGifting(this.state.giftingSelected);                       
            
            if(!this.props.checkout.isLayby) this.props.updateManufactureNow(false);
            if(this.state.giftingSelected){            
                this.setState({giftingType: 1})
                this.props.updateGiftingType(1)            
            } else {
                this.setState({giftingType: 0})
                this.props.updateGiftingType(0)
            }      
        }, 100);          
    }

    updateRadio = (isGifting : boolean) => {
        this.setState({giftingSelected: isGifting})
    }

    selectEcardOption = (giftingType: number) => {
        // this.setState({giftingType: 0})
        // if (this.state.giftingType == giftingType){
        //     this.props.updateGiftingType(0);            
        // } else{
        //     this.props.updateGiftingType(giftingType);
        //     this.setState({giftingType: giftingType})
        // }   
    }

    continue= () =>{
        // if(this.state.giftingType==0 && this.props.checkout.isGifting){
        //     return
        // }
        if(this.props.checkout.ownerIsApplicant && !this.props.checkout.isLayby) {
            navigate('/plate-manufacture-selection/') 
        } else{
            navigate('/personal-details/') 
        }
    }

    render(){

        const {heading, subHeading, body, applicantCard, giftingCard, forSomeoneElseCard, checkout, eCard} = this.props;  

        return (
            <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <H2>
                        {heading}
                    </H2>
                    <PlateOwnerSelectionSubHeading>{subHeading}</PlateOwnerSelectionSubHeading>
                    <Paragraph1 color='#707070'>
                        {body}
                    </Paragraph1>
                    <Grid stretched>
                        <Grid.Column mobile={16} tablet={8} computer={7}>
                            <CheckoutSelectionCard card={applicantCard} onSelect={() => this.selectApplicantOption()} isSelected={checkout.ownerIsApplicant}></CheckoutSelectionCard>
                        </Grid.Column>
                        {this.props.transactionTypeId == TransactionTypesSetting.New.id ? <Grid.Column mobile={16} tablet={8} computer={7}>
                            <CheckoutSelectionCardWithRadios updateGiftingType={this.props.updateGiftingType} updateIsGifting={this.props.updateIsGifting} 
                            updateRadio={this.updateRadio} card={forSomeoneElseCard} onSelect={() => this.selectGiftingOption()} isSelected={!checkout.ownerIsApplicant} 
                            checkout={this.props.checkout} secondCard={giftingCard}></CheckoutSelectionCardWithRadios>
                        </Grid.Column> : 
                        <Grid.Column mobile={16} tablet={8} computer={7}>
                            <CheckoutSelectionCard  card={forSomeoneElseCard} onSelect={() => this.selectGiftingOption()} isSelected={!checkout.ownerIsApplicant}></CheckoutSelectionCard>
                        </Grid.Column>}
                        {/* <Grid.Column mobile={16} tablet={6} computer={5}>
                            <CheckoutSelectionCard card={giftingCard} onSelect={() => this.selectGiftingOption(true)} isSelected={checkout.isGifting}></CheckoutSelectionCard>
                        </Grid.Column> */}
                    </Grid>
                </Grid.Column>  
            </Grid.Row>
            {this.props.checkout.giftingType == 1 && 
            <>
                <Grid.Row>
                    <H5>
                        {this.props.giftingOptionElements.elements.heading.value}&nbsp;
                    </H5>
                    <Paragraph1 color={theme.mobileTheme.color}>
                        <div>{this.props.giftingOptionElements.elements.sub_heading.value}</div>
                    </Paragraph1>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                        <CheckoutSelectionCardContainer /* className={this.state.giftingType == 1 ? 'selected' :  ''} */ onClick={() => this.selectEcardOption(1)}>
                            <div>                            
                                <CheckoutSelectionCardHeading><EcardImg src={ecardImg} alt="Kiwiplates - E-Cart" />{eCard.heading}</CheckoutSelectionCardHeading>
                                {eCard.body && <span style={{color: theme.brand.colors.darkGrey}}>{eCard.body}</span>}
                            </div>
                            <div>
                                {eCard.content && <span style={{color: '#3DAE2B'}} dangerouslySetInnerHTML={{ __html: eCard.content }} />}
                                {/* <GiftingOptionsSelectionCardButton onClick={() => this.selectEcardOption(1)} id="csc-select" buttonType='secondary' color={theme.mobileTheme.buttonBgColor} textColor={theme.mobileTheme.buttonColor}>{this.state.giftingType == 1 ? '- REMOVE' : '+ ADD'}</GiftingOptionsSelectionCardButton> */}
                            </div>                    
                            {/* {
                                this.state.giftingType == 1 &&
                                <CheckoutSelectionCardIndicator>
                                    <Icon name='check' size='small' />
                                </CheckoutSelectionCardIndicator>
                            } */}
                        </CheckoutSelectionCardContainer>
                    </Grid.Column>
                </Grid.Row>
            </>
            
            }
            {
                this.props.checkout.giftingType == 1 &&  
                <div style={{marginTop: "20px"}}>                
                    <EcardContainer updatePersonalDetails={this.props.updatePersonalDetails} apiUrl={this.props.apiUrl} checkout={this.props.checkout} eCardTemplate={this.props.eCardTemplate}/>
                </div>
            }
            {this.props.checkout.giftingType == 0 &&<Grid.Row verticalAlign='bottom'>
                <Grid.Column width={16}>
                <Grid reversed='mobile vertically'>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                    <KpButton id="pos-back" fullWidth="mobile"  buttonType='secondary' color={theme.brand.colors.black} link='/shopping-cart'>BACK</KpButton>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} textAlign='right'>
                    <KpButton id="pos-continue" fullWidth="mobile"  buttonType='primary' color={theme.brand.colors.blue} onClick={() => this.continue()} >CONTINUE</KpButton>
                    </Grid.Column>
                </Grid>
                </Grid.Column>
            </Grid.Row>}
        </Grid>      
        );
    }
}
export default PlateOwnerSelection